import { faListOl, faSignOutAlt, faArrowLeft, faArrowRight, faLanguage, faArrowCircleLeft, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import { removeChildren, mkNode, scrollRangeIntoView, safeJsonParse, isIndexed } from "@p4b/utils";
import { QuestionViewerElement, evalExprBool } from "@p4b/question-viewer";
import { AnswerKey, AnswerValue, Expr, QuestionManifest } from '@p4b/question-base';
//import { Img } from 'image-base';
import { ResponseModel, ResponseStatus, Structure, LocalData, RemoteData, Storage } from '@p4b/exam-service';
//import {isTouchDevice, hasMouse} from './utils-device';
import { examCleanup, urlWithCredentials } from '@p4b/utils-net';
import { dbGet, dbPut, dbClearSelect } from '@p4b/utils-db';
import { pinToKey } from '@p4b/utils-zip';
import { alertModal, Modal } from '@p4b/utils-progress';
import { translate } from '@p4b/utils-lang';
import { ReconnectingEventSource } from '@p4b/utils-events';
import { RobotCat } from '@p4b/robot-cat';
import { Accessibility } from '@p4b/exam-accessibility';
import { ExamTimer, isSchedule, VersionedSchedule, isVersionedSchedule } from '@p4b/exam-timer';
import ResizeObserver from 'resize-observer-polyfill';
import { ComponentDetails, ConnectionStatus, MeetingEvent, MeetingEventObserver, MeetingViewer } from '@p4b/meeting';
import { NoteViewer } from '@p4b/notes';
import { Highlight } from '@p4b/highlight';
import { Calculator } from '@p4b/calculator';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare global {
    interface EventSourceEventMap {
        'exam-pending': MessageEvent;
        'exam-started': MessageEvent;
        'exam-paused': MessageEvent;
        'exam-resumed': MessageEvent;
        'exam-stopped': MessageEvent;
        'exam-deleted': MessageEvent;
        'exam-time': MessageEvent;
    }
}

//interface Storage {
//    getImageBegin(): Promise<void>;
//    getImageFrame(image: Img, frame: number): Promise<Uint8Array>;
//    getImageEnd(): Promise<void>;
//}

interface ExamCxt {
    content: HTMLElement;
    structure: Structure[];
    imageStore: Storage;
    //examId: string;
    //showQuestionTitle: boolean;
    //demo: boolean;
    //getStatus: (examId: string, candidateId: string) => Promise<StateData[]>;
    //loadFlags: () => Promise<Flags[]>;
    //storeFlag: (qno: number, ano: number, flag: boolean) => Promise<void>;
    //noMouse: boolean;
    //loadAnswer: (args: BackendId) => Promise<SaveData>;
    candidateId: string;
    //order: number[];
    factorDetails?: {[ix: string]: PractiqueNet.ExamJson.Definitions.UserDetails};
    component?: ComponentDetails;
    //enableCopyPaste: boolean;
    //disableResourceLocking: boolean;
    meta: PractiqueNet.ExamJson.Definitions.ExamMeta;
    onDestroy: () => Promise<void>;
}

interface ExamContext extends ExamCxt {
    responses: ResponseModel;
    onDestroy: () => Promise<void>;
}

function overviewPos(structure: Structure, aid: number) {
    let x = 0;
    for(let i = 0; i < aid; ++i) {
        if (structure.answerType[aid] !== 'label') {
            ++x;
        }
    }
    return x + 1;
}

/*
function mkOverview(structure: Structure, qid: number, aid: number, navigateTo: (i: number, j: number) => Promise<void>): Overview {
    return new Overview({qid, aid,
        backendQid: structure.backendQid[0],
        backendAid: structure.backendAid[aid],
        backendFid: structure.factor,
        displayId: (structure.displayNumber[aid] !== undefined)
            ? structure.displayNumber[aid]?.trim()
            : (structure.backendAid.length > 1 && structure.answerType[aid] !== 'label')
                ? String(overviewPos(structure, aid))
                : '',
        visible: structure.visible[aid],
        navigateTo: navigateTo,
        showOverview: structure.answerType[aid] !== 'label',
        text: `${
            (structure.factor !== undefined)
                ? `${structure.round} ${structure.factor}`
                : (structure.round !== undefined)
                    ? `${structure.round} ${translate('OVERVIEW_NO_CANDIDATE')}`
                    : qid + 1
        }${
            (structure.displayNumber[aid] !== undefined)
                ? ' '.repeat((structure.indents?.[aid] ?? 0) + 1) + structure.displayNumber[aid]
                : (structure.backendAid.length > 1)
                    ? '.' + overviewPos(structure, aid)
                    : ''
        }`,
    });
}
*/

class Toolbar {
    public readonly toolBar: HTMLDivElement;
    public readonly statusBar: HTMLDivElement;
    public readonly questionReview: HTMLButtonElement;
    public readonly langControl: HTMLButtonElement;
    public readonly logoutControl: HTMLButtonElement;
    public readonly cid: HTMLSpanElement;
    public readonly appCid: HTMLButtonElement;
    public readonly navControls: HTMLDivElement;
    public readonly prevControl: HTMLButtonElement;
    public readonly nextControl: HTMLButtonElement;
    public readonly prevCandidate: HTMLButtonElement;
    public readonly nextCandidate: HTMLButtonElement;

    constructor(context: ExamContext, parent: HTMLElement) {
        this.toolBar = mkNode('div', {className: 'tool-bar-vbox config-primary', parent});
        this.statusBar = mkNode('div', {className: 'status-bar', parent: this.toolBar});

        this.questionReview = mkNode('button', {
            className: appButtonClasses, parent: this.statusBar, children: [
                mkNode('icon', { icon: faListOl }),
                mkNode('span', {
                    className: 'app-button-text', children: [
                        mkNode('text', { text: translate('CONTROL_OVERVIEW') })
                    ]
                })
            ],
            attrib: {id: 'button-overview'}
        });

        this.langControl = mkNode('button', {
            className: appButtonClasses, parent: this.statusBar, children: [
                mkNode('icon', { icon: faLanguage }),
                mkNode('span', {
                    className: 'app-button-text', children: [
                        mkNode('text', { text: translate('CONTROL_LANGUAGE') })
                    ]
                })
            ],
            attrib: {id: 'button-language'}
        });

        this.logoutControl = mkNode('button', {
            className: appButtonClasses, parent: this.statusBar, children: [
                mkNode('icon', { icon: faSignOutAlt }),
                mkNode('span', {
                    className: 'app-button-text', children: [
                        mkNode('text', { text: translate('CONTROL_FINISH') })
                    ]
                })
            ],
            attrib: {id: 'button-finish'}
        });

        this.cid = mkNode('span', {className: 'app-text'}),
        this.appCid = mkNode('button', {
            className: appButtonClasses,
            parent: this.statusBar,
            attrib: { disabled: 'true', id: 'button-userid' },
            children: [
                this.cid,
                mkNode('span', { className: 'app-button-text', children: [
                    mkNode('text', { text: translate('CONTROL_USERID') })
                ]}),
            ]
        });

        const isOSCE = context.structure[0]?.round !== undefined;
        this.navControls = mkNode('div', { className: 'app-together', parent: this.statusBar });
        mkNode('div', { className: 'app-space', parent: this.navControls });

        this.prevControl = mkNode('button', {
            id: 'prev-button', className: appButtonClasses, parent: this.navControls, children: [
                mkNode('icon', { icon: isOSCE?faArrowCircleLeft:faArrowLeft }),
                mkNode('span', {
                    className: 'app-button-text', children: [
                        mkNode('text', { text: translate(isOSCE?'CONTROL_PREVIOUS_CASE':'CONTROL_PREVIOUS') })
                    ]
                })
            ]
        });

        this.nextControl = mkNode('button', {
            id: 'next-button', className: appButtonClasses, parent: this.navControls, children: [
                mkNode('icon', { icon: isOSCE?faArrowCircleRight:faArrowRight }),
                mkNode('span', {
                    className: 'app-button-text', children: [
                        mkNode('text', { text: translate(isOSCE?'CONTROL_NEXT_CASE':'CONTROL_NEXT') })
                    ]
                }),
                mkNode('span', {
                    className: 'app-button-text', children: [
                        mkNode('text', { text: translate(isOSCE?'CONTROL_PREVIOUS_CASE':'CONTROL_PREVIOUS') })
                    ], attrib: { style: "visibility:hidden; height: 0;" }
                }),
            ]
        });

        const isCandidate = context.component === ComponentDetails.ROLE_CANDIDATE;
        this.prevCandidate = mkNode('button', {
            id: 'prev-button', className: appButtonClasses, parent: this.navControls, children: [
                mkNode('icon', { icon: isOSCE?faArrowLeft:faArrowCircleLeft }),
                mkNode('span', {
                    className: 'app-button-text', children: [
                        mkNode('text', { text: translate(isCandidate?'CONTROL_PREVIOUS_STATION':'CONTROL_PREVIOUS_CANDIDATE') })
                    ]
                })
            ]
        });
        this.nextCandidate = mkNode('button', {
            id: 'next-button', className: appButtonClasses, parent: this.navControls, children: [
                mkNode('icon', { icon: isOSCE?faArrowRight:faArrowCircleRight }),
                mkNode('span', {
                    className: 'app-button-text', children: [
                        mkNode('text', { text: translate(isCandidate?'CONTROL_NEXT_STATION':'CONTROL_NEXT_CANDIDATE') })
                    ]
                }),
                mkNode('span', {
                    className: 'app-button-text', children: [
                        mkNode('text', { text: translate(isCandidate?'CONTROL_PREVIOUS_STATION':'CONTROL_PREVIOUS_CANDIDATE') })
                    ], attrib: { style: "visibility:hidden; height: 0;" }
                }),
            ]
        });
    }

    add(control: HTMLElement) {
        if (control.parentElement === this.statusBar) {
            return false;
        } else {
            this.statusBar.insertBefore(control, this.appCid);
            return true;
        }
    }

    remove(control: HTMLElement) {
        if (control.parentElement === this.statusBar) {
            this.statusBar.removeChild(control);
            return true;
        } else {
            return false;
        }
    }

    panel() {
        return this.toolBar;
    }
}

class Overview {
    private state = ResponseStatus.emptyRemote;
    public readonly questionReview = mkNode('button', { className: 'question-review config-background config-background-hover' });
    private readonly summaryText = mkNode('text', { parent: this.questionReview });
    public readonly flag = mkNode('span', {
        parent: this.questionReview,
        style: {float: 'right', visibility: 'hidden'},
        children: [
            mkNode('text', { text: '\u2691' }),
        ],
    });
    public flagged = false;

    private readonly qid: number;
    public readonly aid: number;
    private readonly backendQid: number;
    private readonly backendAid: number;
    private readonly backendFid?: string;
    public readonly displayId?: string;
    public readonly visible?: Expr;
    private readonly showOverview: boolean;
    private readonly navigateTo: (i: number, j: number) => Promise<void>;
    public readonly mandatory: boolean;
    public readonly optional: boolean;

    constructor(structure: Structure, qid: number, aid: number, navigateTo: (i: number, j: number) => Promise<void>, showOverview: boolean) {
        this.qid = qid;
        this.aid = aid;
        this.navigateTo = navigateTo;
        this.showOverview = showOverview;
        this.backendQid = structure.backendQid[0];
        this.backendAid = structure.backendAid[aid];
        this.backendFid = structure.factor;
        this.mandatory = structure.mandatory?.[aid] === true;
        this.optional = aid !== -1 && structure.answerType[aid] !== 'label';
        this.visible = structure.visible[aid];
        this.displayId = (structure.displayNumber[aid] !== undefined)
            ? structure.displayNumber[aid]?.trim()
            : (structure.backendAid.length > 1 && aid >=0 && structure.answerType[aid] !== 'label')
                ? String(overviewPos(structure, aid))
                : '';
        this.summaryText.textContent = `${
                (structure.factor !== undefined)
                    ? `${structure.round} ${structure.factor}`
                    : (structure.round !== undefined)
                        ? `${structure.round} ${translate('OVERVIEW_NO_CANDIDATE')}`
                        : qid + 1
            }${
                (structure.round !== undefined && structure.case !== undefined)
                    ? ` ${structure.case}`
                    : ''
            }${
                (structure.displayNumber[aid] !== undefined)
                    ? ' '.repeat((structure.indents?.[aid] ?? 0) + 1) + structure.displayNumber[aid]
                    : (structure.backendAid.length > 1 && aid >= 0 && structure.answerType[aid] !== 'label')
                        ? `.${overviewPos(structure, aid)}`
                        : ''
            }`;
    }

    async select(): Promise<void> {
        this.questionReview.setAttribute('aria-pressed', 'true');
        await this.navigateTo(this.qid, this.aid);
        this.questionReview.setAttribute('aria-pressed', 'false');
    }

    setVisible(vis: boolean): void {
        this.questionReview.style.display = (this.showOverview && vis) ? 'block' : 'none';
    }

    setStatus(state: ResponseStatus): void {
        this.state = state;
        switch (state) {
            case ResponseStatus.emptyLocal:
                this.questionReview.className = 'question-review config-background config-background-hover';
                break;
            case ResponseStatus.savedLocal:
                this.questionReview.className = 'question-review config-warn config-warn-hover';
                break;
            case ResponseStatus.emptyRemote:
                this.questionReview.className = 'question-review config-background config-background-hover';
                break;
            case ResponseStatus.savedRemote:
                this.questionReview.className = 'question-review config-safe config-safe-hover';
                break;
        }
    }

    setInvalid(): void {
        this.questionReview.className = 'question-review config-dngr config-dngr-hover';
    }

    getStatus(): ResponseStatus {
        return this.state;
    }
}

interface TimingMessage {
    timestamp: number;
    users: string[];
    schedule: PractiqueNet.ExamJson.Definitions.Schedule;
    scheduleVersion: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isTimingMessage(x: any): x is TimingMessage {
    if (!isIndexed(x)) {
        console.warn('TimingMessage is not an object');
        return false;
    }
    if (!isSchedule(x.schedule)) {
        console.warn('TimingMessage has invalid or missing Schedule');
        return false;
    }
    if (!Array.isArray(x.users)) {
        console.warn('TimingMessage has invalid or missing users list');
    }
    if (typeof x.scheduleVersion !== 'number') {
        console.warn('TimingMessage has invalid or missing scheduleVersion');
        return false;
    }
    if (typeof x.timestamp !== 'number') {
        console.warn('TimingMessage has invalid or missing timestamp');
        return false;
    }
    return true;
}

interface ControlMessage {
    timestamp: number;
    users: string[];
    pin?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isControlMessage(x: any): x is ControlMessage {
    return x && typeof x === 'object' &&
        typeof x.timestamp === 'number' &&
        (typeof x.pin === 'undefined' || typeof x.pin === 'string') &&
        (typeof x.users === 'undefined' || Array.isArray(x.users));
}

const appButtonClasses = 'app-button config-primary-hover config-primary-fg-shadow-focus';

class ExamViewer implements MeetingEventObserver {
    private contentPanel: HTMLDivElement;
    private toolBar: HTMLDivElement;
    private statusBar: Toolbar;
    private meetingBar: HTMLDivElement;
    private notificationArea: HTMLDivElement;
    private hPanel: HTMLDivElement;
    private reviewPanel: HTMLDivElement;
    private reviewInner: HTMLDivElement;
    private examPanel: HTMLDivElement;
    private logoutModal: Modal;
    private questionUi: QuestionViewerElement;
    private qid: number;
    private aid: number | null = null;
    private noPrev: boolean;
    private noNext: boolean;
    private noPrevCand: boolean;
    private noNextCand: boolean;
    private isInvalid: boolean;
    private isNavigating: boolean;
    private isForwardOnly: boolean;
    private isForced = false;
    private overview: Overview[][];
    private structure: Structure[];
    private demo: boolean;
    private currentLanguage: number;
    private candidateId: string;
    private examId: string;
    private meeting: MeetingViewer;
    private onDestroy: () => Promise<void>;
    private responses: ResponseModel;
    private eventSource?: ReconnectingEventSource;
    private examTimer: ExamTimer;
    private calculator?: Calculator;
    private accessibility?: Accessibility;
    private robotCat?: RobotCat;
    private resizeObserver: ResizeObserver;
    private noteViewer?: NoteViewer;
    private reviewButtonVisible: boolean;
    private highlight?: Highlight;
    private component?: ComponentDetails;
    private isOSCE: boolean;

    private updateNavigation(): void {
        const isNavigationLocked = this.questionUi.getNavigationLocked();
        const isResourceLocked = this.questionUi.getResourceLocked();
        const isReadOnly = this.questionUi.getReadOnly();
        console.debug(`LOCK navigation=${this.isNavigating} invalid=${this.isInvalid} navLocked=${isNavigationLocked} resLocked=${isResourceLocked} forced=${this.isForced}`);
        this.statusBar.prevControl.disabled = this.noPrev || this.isInvalid || this.isNavigating || isNavigationLocked || (!this.isOSCE && this.isForced) || this.isForwardOnly;
        this.statusBar.nextControl.disabled = this.noNext || this.isInvalid || this.isNavigating || isNavigationLocked || (!this.isOSCE && this.isForced);
        this.statusBar.prevControl.style.display = ((this.noPrev && this.noNext) || this.isForwardOnly) ? 'none' : 'flex';
        this.statusBar.nextControl.style.display = (this.noPrev && this.noNext) ? 'none' : 'flex';
        this.statusBar.prevCandidate.disabled = this.noPrevCand || this.isInvalid || this.isNavigating || isNavigationLocked || this.isForced || this.isForwardOnly;
        this.statusBar.nextCandidate.disabled = this.noNextCand || this.isInvalid || this.isNavigating || isNavigationLocked || this.isForced;
        this.statusBar.prevCandidate.style.display = ((this.noPrevCand && this.noNextCand) || this.isForwardOnly) ? 'none' : 'flex';
        this.statusBar.nextCandidate.style.display = (this.noNextCand && this.noPrevCand) ? 'none' : 'flex';
        this.statusBar.logoutControl.disabled = this.isInvalid || this.isNavigating;
        //this.statusBar.questionReview.disabled = isNavigationLocked;
        this.statusBar.langControl.disabled = isNavigationLocked;
        this.calculator?.disabled(isReadOnly);
        this.noteViewer?.disabled(isReadOnly);
        this.highlight?.disabled(isReadOnly);
        this.meeting?.disabled(this.isNavigating);
        if (this.isNavigating || this.isInvalid || this.isForced || isNavigationLocked || this.isForwardOnly) {
            for (let i = 0; i < this.overview.length; ++i) {
                const ss = this.overview[i];
                if (i == this.qid) {
                    for (let j = 0; j < ss.length; ++j) {
                        //ss[j].questionReview.style.color = 'rgba(0, 0, 0, 0.7)';
                        ss[j].questionReview.style.fontWeight = 'bold';
                        ss[j].questionReview.disabled = true;
                    }
                } else {
                    for (let j = 0; j < ss.length; ++j) {
                        //ss[j].questionReview.style.color = 'rgba(0, 0, 0, 0.3)';
                        ss[j].questionReview.style.fontWeight = 'normal';
                        ss[j].questionReview.disabled = true;
                    }
                }
            }
        } else {
            let first;
            let last;
            for (let i = 0; i < this.overview.length; ++i) {
                const ss = this.overview[i];
                if (i == this.qid) {
                    for (let j = 0; j < ss.length; ++j) {
                        //ss[j].questionReview.style.color = 'rgba(0, 0, 0, 1)';
                        ss[j].questionReview.style.fontWeight = 'bold';
                        ss[j].questionReview.disabled = false;
                        if (ss[j].questionReview.style.display !== 'none') {
                            if (first === undefined) {
                                first = ss[j].questionReview;
                            }
                            last = ss[j].questionReview;
                        }
                    }
                } else {
                    for (let j = 0; j < ss.length; ++j) {
                        //ss[j].questionReview.style.color = 'rgba(0, 0, 0, 0.7)';
                        ss[j].questionReview.style.fontWeight = 'normal';
                        ss[j].questionReview.disabled = false;
                    }
                }
            }
            if (first) {
                scrollRangeIntoView(first, last);
            }
        }
        this.questionUi.setNavigating(this.isNavigating);
    }

    private async findAnsPromise(aid: number): Promise<string> {
        for (let i = 0; i < this.overview.length; ++i) {
            const ss = this.overview[i];
            for (let j = 0; j < ss.length; ++j) {
                if (ss[j].aid === aid) {
                    try {
                        const response = await this.responses.getAnswer(i, j);
                        if (response) {
                            return JSON.stringify(response.answer);
                        } else {
                            return '';
                        }
                    } catch (err) {
                        console.error(err);
                        return '';
                    }
                }
            }
        }
        return '';
    }

    private readonly handleStatus = (i: number, j: number, state: ResponseStatus) => {
        if (j >= 0) {
            this.overview[i][j].setStatus(state);
        }
    }

    private checkSubmitted(): {
        mandatoryQuestions: number,
        optionalQuestions: number,
        mandatoryUnanswered: number,
        optionalUnanswered: number,
        unsubmitted: number
    } {
        let mandatoryQuestions = 0;
        let optionalQuestions = 0;
        let mandatoryUnanswered = 0;
        let optionalUnanswered = 0;
        let unsubmitted = 0;

        for (let qid = 0; qid < this.overview.length; ++qid) {
            const ss = this.overview[qid];
            for (let aid = 0; aid < ss.length; ++aid) {
                const aa = ss[aid];
                if (aa.mandatory) {
                    ++mandatoryQuestions;
                } else if (aa.optional) {
                    ++optionalQuestions;
                }
                const state = aa.getStatus();
                if (state === ResponseStatus.emptyLocal || state === ResponseStatus.emptyRemote) {
                    if (aa.mandatory) {
                        ++mandatoryUnanswered;
                        aa.setInvalid();
                    } else if (aa.optional) {
                        ++optionalUnanswered;
                    }
                }
                if (state === ResponseStatus.emptyLocal || state === ResponseStatus.savedLocal) {
                    if (aa.mandatory || aa.optional) {
                        ++unsubmitted;
                    }
                }
            }
        }

        return {mandatoryQuestions, optionalQuestions, mandatoryUnanswered, optionalUnanswered, unsubmitted};
    }

    private showSubmitted({mandatoryQuestions, optionalQuestions, mandatoryUnanswered, optionalUnanswered, unsubmitted}: {mandatoryQuestions: number, optionalQuestions: number, mandatoryUnanswered: number, optionalUnanswered: number, unsubmitted: number}, message = ''): void {
        if (mandatoryUnanswered > 0) {
            this.showReview(true);
        }
        let html = message;
        const totalQuestions = mandatoryQuestions + optionalQuestions;
        if (totalQuestions > 0) {
            if (unsubmitted > 0) {
                html += translate('FINISH_UNSUBMITTED', {unsubmitted});
            } else {
                html += translate('FINISH_SUBMITTED');
            }
            const totalUnanswered = mandatoryUnanswered + optionalUnanswered;
            if (totalUnanswered > 0) {
                if (this.isOSCE) {
                    html += translate('FINISH_MANDATORY_UNANSWERED', {totalUnanswered, mandatoryUnanswered});
                } else {
                    html += translate('FINISH_UNANSWERED', {totalUnanswered});
                }
            } else {
                html += translate('FINISH_ANSWERED');
            }
        }
        this.logoutModal.bodyHtml(html);
    }

    public constructor({context, startQuestion, init}: {context: ExamContext, startQuestion: StartQuestion, init: {schedule: VersionedSchedule, elapsed: number, timestamp?: number}}) {
        const {schedule, elapsed, timestamp} = init;
        this.isOSCE = context.structure[0]?.room != undefined;
        if (context.content != null) {
            removeChildren(context.content);
        }
        this.contentPanel = mkNode('div', {className: 'content-panel config-background', parent: context.content});
        this.statusBar = new Toolbar(context, this.contentPanel);
        this.toolBar = this.statusBar.panel();
        this.meetingBar = mkNode('div', {className: 'sub-control', parent: this.toolBar});
        this.notificationArea = mkNode('div', {className: 'message-hidden', parent: this.contentPanel});
        mkNode('div', {className: 'message-container', parent: this.contentPanel, children:[this.notificationArea]});
        console.debug('STRUCTURE', context.structure);

        this.reviewButtonVisible = context.structure.reduce((x, y) => x + y.backendAid.length, 0) > 0;
        this.statusBar.questionReview.style.display = this.reviewButtonVisible ? 'inline-flex' : 'none';

        this.statusBar.langControl.style.display = (context.structure[0]?.backendQid.length > 1) ? 'inline-flex' : 'none';

        this.hPanel = mkNode('div', { className: 'h-panel', parent: this.contentPanel });
        this.reviewPanel = mkNode('div', {
            className: 'review-panel config-background', parent: this.hPanel,
        });
        this.reviewPanel.style.display = (context.structure.length <= 0 || this.isOSCE || context.component === ComponentDetails.ROLE_OBSERVER) ? 'none' : 'block';
        this.reviewInner = mkNode('div', {
            className: 'review-pannel-inner', parent: this.reviewPanel, children: [
                mkNode('div', {
                    className: 'config-primary review-heading', children: [
                        mkNode('text', { text: translate('OVERVIEW_TITLE') })
                    ]
                })
            ]
        });
        this.examPanel = mkNode('div', { className: 'exam-panel config-background', parent: this.hPanel });
        this.logoutModal = new Modal({
            parent: this.contentPanel,
            handler: this.handleModal,
        });
        if (!context.meta.disableCalculator) {
            this.calculator = new Calculator(this.statusBar);
        }
        this.accessibility = new Accessibility(this.statusBar);
        this.robotCat = new RobotCat(this.statusBar);
        this.qid = startQuestion.question;
        this.currentLanguage = startQuestion.language;
        this.noPrev = false;
        this.noNext = false;
        this.noPrevCand = false;
        this.noNextCand = false;
        this.isInvalid = false;
        this.isNavigating = true;
        this.isForwardOnly = context.meta.disableBackwardsNavigation ?? false;
        this.candidateId = context.candidateId;
        this.component = context.component;
        console.debug('MANIFEST', context.meta);
        this.meeting = new MeetingViewer(
            (x) => this.setNavigating(x),
            this.statusBar,
            this.meetingBar,
            context.meta.answer_aes_key,
            this.candidateId,
            context.structure[0]?.round !== undefined,
            this,
            //interview,
            this.contentPanel,
            context.meta.disableScreensharing === true,
            context.component,
            context.responses,
        );
        this.questionUi = document.createElement('question-viewer');
        this.questionUi.setAttribute('candidate-id', context.candidateId);
        if (context.component) {
            this.questionUi.setAttribute('component', String(Number(context.component)));
        }
        this.questionUi.args({
            responses: {
                saveAnswer: async (key: AnswerKey, value: AnswerValue): Promise<void> => {
                    try {
                        await context.responses.setAnswerLocal(key, {...value, elapsed: this.examTimer.getElapsed()}, this.handleStatus);
                        this.updateStatus({silent: true}); // async
                    } catch (err) {
                        console.error(err);
                        alertModal(String(err));
                    }
                },
                loadAnswer: async (qno: number, ano: number): Promise<LocalData|undefined> => {
                    return await context.responses.getAnswer(qno, ano);
                },
                setVisible: (qno: number, ano: number, vis: boolean): void => {
                    this.overview[qno][ano].setVisible(vis);
                },
                setFlag: async (qno: number, ano: number, flag: boolean): Promise<void> => {
                    const ss = this.overview[qno];
                    ss[ano].flagged = flag;
                    if (flag) {
                        ss[ano].flag.style.visibility = 'visible';
                    } else {
                        ss[ano].flag.style.visibility = 'hidden';
                    }
                    await context.responses.setFlag(qno, ano, flag);
                },
                getFlag: async (qno: number, ano: number): Promise<boolean> => {
                    return this.overview[qno][ano]?.flagged;
                },
                setValid: (v: boolean): void => {
                    this.isInvalid = !v;
                    this.updateNavigation();
                },
                setInvalid: (iqno: number, iano:number): void => {
                    this.overview[iqno][iano].setInvalid();
                },
                getValid: (): boolean => {
                    return !this.isInvalid;
                },
                getDisplayId: (qno: number, ano: number): string|undefined => {
                    return this.overview[qno][ano]?.displayId;
                },
            },
            questions: {
                getQuestion: async (index: number) => {
                    return await dbGet<QuestionManifest>('questions', index)
                },
            },
            resources: {
                getImageBegin: async () => await context.imageStore.getImageBegin(),
                getImageFrame: async (start: number, end: number) => (await context.imageStore.getImageFrame(start, end))?.buffer,
                getImageEnd: async () => await context.imageStore.getImageEnd(),
            },
            navigation: {
                setNavigating: (x: boolean) => this.setNavigating(x),
                getNavigating: () => this.isNavigating,
            },
            meetingBar: {
                add: (x: HTMLElement) => {
                    this.meetingBar.appendChild(x);
                }
            },
            notifications: {
                warning: (message: string) => {
                    this.notificationArea.innerHTML = message;
                    this.notificationArea.className= 'message-warning';
                },
                none: () => {
                    this.notificationArea.className = 'message-hidden';
                }
            },
            timing: {
                getTimers: () => this.examTimer,
            },
            parent: this.examPanel,
            fullscreenParent: this.contentPanel,
            controlPanel: this.statusBar,
            factorDetails: context.factorDetails,
            candidateId: context.candidateId,
            component: context.component,
            meeting: this.meeting,
            meta: context.meta,
        });
        this.examPanel.appendChild(this.questionUi);

        this.overview = [];
        const frag = document.createDocumentFragment();
        for (let i = 0; i < context.structure.length; ++i) {
            const elems = [];
            const structureClick = async (qid: number, aid: number) => {
                if (this.isNavigating || this.isInvalid || (this.isForced && (this.structure[this.qid].round !== this.structure[qid].round)) || this.questionUi.getNavigationLocked()) {
                    return;
                }
                if (qid != null && qid != this.qid) {
                    this.isNavigating = true;
                    this.updateNavigation();
                    try {
                        if (await this.questionUi.setQuestion({
                            structure: this.structure[qid],
                            language: this.currentLanguage,
                            time: this.examTimer.getElapsed(),
                            forced: this.isForced,
                        })) {
                            this.qid = qid;
                        }
                        this.examTimer.update();
                        this.noteViewer?.load(await dbGet('notes', this.qid));
                        this.highlight?.load(await dbGet('notes', this.qid + '#'));
                    } catch (e) {
                        console.error(e);
                    } finally {
                        this.isNavigating = false;
                        this.noPrev = this.qid === this.prevCase();
                        this.noNext = this.qid === this.nextCase();
                        this.noPrevCand = this.qid === this.prevCand();
                        this.noNextCand = this.qid === this.nextCand();
                        this.updateNavigation();
                    }
                }
                if (aid != null && aid != this.aid) {
                    this.aid = aid;
                }
                if (this.aid != null) {
                    this.questionUi.setFocus(this.aid);
                }
            };
            const responses = context.structure[i].answerType.reduce((acc, type) => (type === 'label') ? acc : acc + 1, 0);
            if (context.structure[i].length > 0) {
                for (let j = 0; j < context.structure[i].length; ++j) {
                    const q = new Overview(context.structure[i], i, j, structureClick, context.structure[i].answerType[j] !== 'label' || (j === 0 && responses === 0));
                    elems.push(q);
                    frag.appendChild(q.questionReview);
                }
                this.overview.push(elems);
            } else {
                const q = new Overview(context.structure[i], i, -1, structureClick, true)
                elems.push(q);
                frag.appendChild(q.questionReview);
                this.overview.push(elems);
            }
        }
        this.reviewInner.appendChild(frag);
        this.reviewPanel.scrollTop = 1;
        this.examPanel.scrollTop = 1;
        this.resizeObserver = new ResizeObserver(this.handleResize);
        this.resizeObserver.observe(this.reviewPanel);
        this.resizeObserver.observe(this.examPanel);
        this.updateNavigation();
        if (context.candidateId) {
            this.statusBar.cid.textContent = context.candidateId;
            this.statusBar.appCid.style.display = 'inline-flex';
        } else {
            this.statusBar.appCid.style.display = 'none';
        }
        //this.clockInterval = window.setInterval((): void => {
        //    this.time.textContent = new Date().toLocaleTimeString().toLowerCase();
        //}, 1000);
        this.responses = context.responses;
        this.onDestroy = context.onDestroy;
        this.structure = context.structure;
        this.demo = context.meta.demo ?? false;
        //this.answer_aes_key = context.answer_aes_key;
        this.examId = context.meta.answer_aes_key;
        window.addEventListener('offline', this.handleOffline);
        window.addEventListener('online', this.handleOnline);
        this.examTimer = new ExamTimer({
            controlPanel: this.statusBar,
            notificationArea: {
                show: (html: string): void => {
                    this.notificationArea.innerHTML = html;
                    this.notificationArea.className= 'message-warning';
                },
                hide: (): void => {
                    if (this.questionUi?.meetingMessage) {
                        this.notificationArea.innerHTML = this.questionUi.meetingMessage;
                        this.notificationArea.className= 'message-warning';
                    } else {
                        this.notificationArea.className = 'message-hidden';
                    }
                },
                setReadOnly: (isReadOnly: boolean) => {
                    this.questionUi.setReadOnly(isReadOnly);
                    this.calculator?.disabled(isReadOnly);
                    this.noteViewer?.disabled(isReadOnly);
                    this.highlight?.disabled(isReadOnly);
                },
                setItem: async (round?: number) => {
                    if (context.component === ComponentDetails.ROLE_OBSERVER) {
                        return;
                    }
                    if (this.setItemBusy) {
                        return;
                    }
                    try {
                        this.setItemBusy = true;
                        if (round === undefined) {
                            if (this.isForced) {
                                this.isForced = false;
                                this.updateNavigation();
                            }
                            return;
                        }
                        if (!this.isForced) {
                            this.isForced = true;
                            this.updateNavigation();
                        }
                        if (round + 1 !== this.structure[this.qid].round) {
                            try {
                                this.isNavigating = true;
                                this.updateNavigation();
                                let q = 0;
                                for (;q < this.structure.length; ++q) {
                                    if (round + 1 === this.structure[q].round) {
                                        break;
                                    }
                                }
                                if (q < this.structure.length) {
                                    if (await this.questionUi.setQuestion({
                                        structure: this.structure[q],
                                        language: this.currentLanguage,
                                        time: this.examTimer.getElapsed(),
                                        forced: this.isForced,
                                    })) {
                                        this.qid = q;
                                    }
                                    this.noteViewer?.load(await dbGet('notes', this.qid));
                                    this.highlight?.load(await dbGet('notes', this.qid + '#'));
                                }
                            } catch (e) {
                                console.error(e);
                            } finally {
                                this.noPrev = this.qid === this.prevCase();
                                this.noNext = this.qid === this.nextCase();
                                this.noPrevCand = this.qid === this.prevCand();
                                this.noNextCand = this.qid === this.nextCand();
                                this.isNavigating = false;
                                this.updateNavigation();
                            }
                        }
                        if (!this.questionUi.getNavigationLocked()) {
                            const meeting = this.meeting;
                            if (meeting && meeting.getConnected() === ConnectionStatus.Disconnected) {
                                await meeting.startMeeting();
                            }
                        }
                    } finally {
                        this.setItemBusy = false;
                    }
                },
                getItem: () => (this.structure[this.qid].round ?? 0) - 1,
            },
            timing: context.meta.timing,
            schedule,
            callback: async () => {
                switch(this.questionUi.getExamType()) {
                    case 'osce':
                        await this.meeting.stopMeeting();
                        this.showFinish();
                        break;
                    case 'written':
                        await this.setState({state: ExamState.Stopped, save: true});
                        break;
                }
                try {
                    await this.updateStatus({silent: true, force:true, scheduleVersion: Number.MAX_SAFE_INTEGER});
                } catch (err) {
                    console.error(err);
                }
            },
            language: this.currentLanguage,
        });
        //this.timing = context.meta.timing;
        //console.debug('EXAM_VIEWER ELAPSED', elapsed);
        this.examTimer.setElapsed(elapsed, timestamp);
        if (!context.meta.disableNotes) {
            this.noteViewer = new NoteViewer(this.statusBar, notes => {
                dbPut('notes', this.qid, notes);
            });
        }
        if (!context.meta.disableHighlighting) {
            this.highlight = new Highlight(this.statusBar, this.examPanel, extents => {
                dbPut('notes', this.qid + '#', extents);
            });
        }
        if (!context.meta.enableCopyPaste) {
            this.contentPanel.addEventListener('cut', this.blackhole_handler);
            this.contentPanel.addEventListener('copy', this.blackhole_handler);
            this.contentPanel.addEventListener('paste', this.blackhole_handler);
        }
    }

    private readonly blackhole_handler = (event: ClipboardEvent) => event.preventDefault();

    private setItemBusy = false;

    private setNavigating(v: boolean|undefined): void {
        if (v !== undefined) {
            this.isNavigating = v;
        }
        this.updateNavigation();
    }

    public async handleMeetingEvent(event: MeetingEvent): Promise<void> {
        return this.questionUi.handleMeetingEvent(event);
    }

    public getResourceStatus(status: {id: string, released: boolean}[]): {id: string, released: boolean}[] {
        return this.questionUi.getResourceStatus(status);
    }

    public setResourceStatus(status?: {id: string, released: boolean}): void {
        return this.questionUi.setResourceStatus(status);
    }

    private readonly handleOffline = () => {
        console.log('OFFLINE');
    }

    private readonly handleOnline = async () => {
        console.log('ONLINE');
        try {
            await this.updateStatus({silent: true});
            //await this.responses.resendUnsent(this.handleStatus);
        } catch (err) {
            console.error(err);
        }
    }

    private state?: ExamState = undefined;

    private async setState({state, save = false}: {state: ExamState, save?: boolean, autoExit?: boolean}): Promise<void> {
        if (this.questionUi.getExamType() === 'written' && this.examTimer.finished() && (state === ExamState.Started || state === ExamState.Paused)) {
            state = ExamState.Stopped;
        }
        if (state === this.state) {
            return;
        }
        console.warn(`STATE: ${this.state} -> ${state}]`);
        this.state = state;
        let saved = Promise.resolve();
        if (save) {
            saved = dbPut<SavedState>('users', 'state', {state, elapsed: this.examTimer.getElapsed(), timestamp: Date.now()});
        }
        switch (state) {
            case ExamState.Waiting:
            case ExamState.Deleted:
                await this.finish(false);
                break;
            case ExamState.Holding:
                if (this.component !== ComponentDetails.ROLE_OBSERVER) {
                    this.questionUi.setNavigationLocked(true);
                    this.questionUi.setReadOnly(true);
                }
                if (this.meeting && this.meeting.getConnected() === ConnectionStatus.Disconnected) {
                    await this.meeting.startMeeting();
                }
                break;
            case ExamState.Started:
                this.logoutModal.hide();
                this.logoutModal.reset();
                this.questionUi.setPaused(false);
                this.calculator?.disabled(false);
                this.noteViewer?.disabled(false);
                this.highlight?.disabled(false);
                this.accessibility?.disable(false);
                this.examTimer.start(); // will set readonly sychronously for reading-time.
                await this.updateStatus({silent: true, force:true});
                break;
            case ExamState.Paused:
                this.examTimer.stop();
                this.questionUi.setPaused(true);
                this.calculator?.disabled(true);
                this.noteViewer?.disabled(true);
                this.highlight?.disabled(true);
                this.accessibility?.disable(true);
                //this.updateNavigation();
                this.logoutModal.reset();
                this.logoutModal.titleHtml(translate('PAUSED_TITLE'));
                //this.logoutModal.addButtons('navbutton config-safe config-safe-hover config-safe-fg-border config-safe-fg-shadow-focus', {submit: translate('FINISH_SUBMIT')});
                this.showSubmitted(this.checkSubmitted(), translate('PAUSED_DESCRIPTION'));
                this.logoutModal.show();
                break;
            case ExamState.Stopped:
                this.examTimer.stop();
                const check = this.checkSubmitted();
                this.questionUi.setPaused(true);
                this.calculator?.disabled(true);
                this.noteViewer?.disabled(true);
                this.highlight?.disabled(true);
                this.accessibility?.disable(true);
                //this.updateNavigation();
                this.logoutModal.reset();
                this.logoutModal.titleHtml(translate('STOPPED_TITLE'));
                //this.logoutModal.addButtons('navbutton config-safe config-safe-hover config-safe-fg-border config-safe-fg-shadow-focus', {submit: translate('FINISH_SUBMIT')});
                this.logoutModal.addButtons('navbutton config-dngr config-dngr-hover config-dngr-fg-border config-dngr-fg-shadow-focus', {logout: translate('FINISH_NOW')});
                //this.logoutModal.disable(true, 'logout');
                this.showSubmitted(check, translate('FINISH_DESCRIPTION'));
                this.logoutModal.show();
                break;
        }
        await saved;
    }

    private readonly handleWaitMessage = async (event: MessageEvent): Promise<void> => {
        const data = safeJsonParse(event.data);
        if (isControlMessage(data) && data.users.indexOf(this.candidateId) > -1) {
            console.log('SSE_WAITING', event);
            await this.setState({state: ExamState.Waiting, save: true});
        }
    }

    private readonly handleStartMessage = async (event: MessageEvent): Promise<void> => {
        const data = safeJsonParse(event.data);
        if (isControlMessage(data) && data.users.indexOf(this.candidateId) > -1) {
            console.log('SSE_START', event);
            await this.setState({state: ExamState.Started, save: true});
        }
    }

    private readonly handlePauseMessage = async (event: MessageEvent): Promise<void> => {
        const data = safeJsonParse(event.data);
        if (isControlMessage(data) && data.users.indexOf(this.candidateId) > -1) {
            console.log('SSE_PAUSE', event);
            await this.setState({state: ExamState.Paused, save: true});
        }
    }

    private readonly handleResumeMessage = async (event: MessageEvent): Promise<void> => {
        const data = safeJsonParse(event.data);
        if (isControlMessage(data) && data.users.indexOf(this.candidateId) > -1) {
            console.log('SSE_RESUME', event);
            await this.setState({state: ExamState.Started, save:true});
        }
    }

    private readonly handleStopMessage = async (event: MessageEvent): Promise<void> => {
        const data = safeJsonParse(event.data);
        if (isControlMessage(data) && data.users.indexOf(this.candidateId) > -1) {
            console.log('SSE_STOP', event);
            await this.setState({state: ExamState.Stopped, save: true});
        }
    }

    private readonly handleDeleteMessage = async (event: MessageEvent): Promise<void> => {
        const data = safeJsonParse(event.data);
        if (isControlMessage(data)) {
            console.log('SSE_DELETE', event);
            await this.setState({state: ExamState.Deleted, save: true});
        }
    }

    private readonly handleTimeMessage = async (event: MessageEvent): Promise<void> => {
        const data = safeJsonParse(event.data);
        console.log('ORIG SSE_TIME', event.data);
        if (isTimingMessage(data) && data.users.indexOf(this.candidateId) > -1) {
            console.log('SSE_TIME', event.data);
            await this.examTimer.setSchedule({schedule: data.schedule, version: data.scheduleVersion});
        }
    }

    private async statsPromise(context: ExamContext): Promise<void> {
        await context.responses.getStatus(this.handleStatus);
    }

    private async flagsPromise(context: ExamContext): Promise<void> {
        const flags = await context.responses.getFlags();
        for (const flag of flags) {
            //console.log(flag.qid, flag.aid, flag.flag);
            if (flag.flag) {
                const s = this.overview[flag.qid];
                s[flag.aid].flagged = true;
                s[flag.aid].flag.style.visibility = 'visible';
            }
        }
    }

    private async questionPromise(startTime: number): Promise<void> {
        await this.questionUi.setQuestion({
            structure: this.structure[this.qid],
            language: this.currentLanguage,
            time: startTime,
            forced: this.isForced,
        });
        await this.questionUi.checkpointQuestion(); // save first question timings.
    }

    private async visibilityPromise(): Promise<void> {
        for (const q of this.overview) {
            for (const a of q) {
                a.setVisible(
                    (a.visible == null) ? true : await evalExprBool(a.visible, async n => await this.findAnsPromise(n))
                );
            }
        }
    }

    private resolve?: (value?: unknown) => void;

    public async init({context, state, startTime}: {context: ExamContext, state: ExamState, startTime: number}): Promise<void> {
        try {
            const p1 = this.flagsPromise(context);
            const p2 = this.statsPromise(context);
            const p3 = this.visibilityPromise();
            await p1;
            await this.questionPromise(startTime);
            await p2;
            await p3;
        } catch (e) {
            console.error(e);
        }
        this.noteViewer?.load(await dbGet('notes', this.qid));
        this.highlight?.load(await dbGet('notes', this.qid + '#'));
        this.noPrev = this.qid === this.prevCase();
        this.noNext = this.qid === this.nextCase();
        this.noPrevCand = this.qid === this.prevCand();
        this.noNextCand = this.qid === this.nextCand();
        this.isNavigating = false;
        this.updateNavigation();

        const passive: AddEventListenerOptions & EventListenerOptions = {passive: true};
        this.examPanel.addEventListener('scroll', this.handleScroll);
        this.reviewPanel.addEventListener('scroll', this.handleScroll);
        this.reviewInner.addEventListener('click', this.handleJump, passive);
        this.statusBar.statusBar.addEventListener('click', this.handleToolbar, passive);
        this.eventSource = new ReconnectingEventSource(new URL(urlWithCredentials(`app/${this.examId}/events/`), window.location.origin));
        this.eventSource.addEventListener('exam-pending', this.handleWaitMessage);
        this.eventSource.addEventListener('exam-started', this.handleStartMessage);
        this.eventSource.addEventListener('exam-paused', this.handlePauseMessage);
        this.eventSource.addEventListener('exam-resumed', this.handleResumeMessage);
        this.eventSource.addEventListener('exam-stopped', this.handleStopMessage);
        this.eventSource.addEventListener('exam-deleted', this.handleDeleteMessage);
        this.eventSource.addEventListener('exam-time', this.handleTimeMessage);
        const wait = new Promise(resolve => {
            this.resolve = resolve;
        });
        await this.setState({state, save: true});
        await wait;
    }

    private updating = false;

    private async updateStatus({silent, force = false, scheduleVersion = this.examTimer.getScheduleVersion()}: {silent: boolean, force?: boolean, scheduleVersion?: number}): Promise<boolean> {
        if (this.updating) {
            return false;
        }
        try {
            this.updating = true;
            while (true) {
                const unsubmitted = await this.responses.getUnsubmitted();

                if (unsubmitted.length === 0 && !force) {
                    return true;
                }
                force = false;

                const {remoteStatus} = await this.responses.fetchStatus({
                    state: this.state,
                    elapsed: this.examTimer.getElapsed(),
                    responses: unsubmitted.map(({remote}) => remote),
                    returnResponses: false,
                    scheduleVersion,
                }, silent);

                if (!(remoteStatus || this.demo)) {
                    return false;
                }

                await this.responses.setSubmitted(unsubmitted, this.handleStatus);

                if (remoteStatus?.schedule) {
                    await this.examTimer.setSchedule({schedule: remoteStatus.schedule, version: remoteStatus.scheduleVersion ?? 0});
                }

                if (remoteStatus?.state) {
                    await this.setState({state: remoteStatus.state, save: true});
                }
            }
        } catch(err) {
            console.error(err);
            alertModal(String(err));
            return false;
        } finally {
            this.updating = false;
        }
    }

    private readonly handleScroll = async (event: Event) => {
        if (event.target instanceof Element) {
            await new Promise(resolve => window.requestAnimationFrame(resolve));
            const {scrollTop, scrollLeft, scrollHeight, clientHeight} = event.target
            , atTop = scrollTop === 0
            , beforeTop = 1
            , atBottom = scrollTop === scrollHeight - clientHeight
            , beforeBottom = scrollHeight - clientHeight - 1
            ;
            if (atTop) {
                event.target.scrollTo(scrollLeft, beforeTop);
            } else if (atBottom) {
                event.target.scrollTo(scrollLeft, beforeBottom);
            }
        }
    }

    private readonly handleResize = () => {
        this.reviewPanel.dispatchEvent(new Event('scroll'));
        this.examPanel.dispatchEvent(new Event('scroll'));
    }

    //private readonly handleBeforeUnload = async () => {
    //    await dbPut<SavedState>('users', 'state', {state: this.state ?? ExamState.Waiting, elapsed: this.examTimer.getElapsed(), timestamp: Date.now()});
    //    await this.questionUi.checkpointQuestion();
    //    console.log('BEFORE UNLOAD SAVED STATE');
    //}

    private showFinish() {
        this.meeting.stopMeeting();
        this.questionUi.setPaused(true);
        this.logoutModal.reset();
        this.logoutModal.titleHtml(translate('FINISH_TITLE'));
        this.logoutModal.addButtons('navbutton config-ntrl config-ntrl-hover config-body-fg-border config-ntrl-fg-border-focus config-body-fg-shadow-focus', {continue: translate('FINISH_CONTINUE')});
        this.logoutModal.addButtons('navbutton config-dngr config-dngr-hover config-body-fg-border config-dngr-fg-border-focus config-body-fg-shadow-focus', {logout: translate('FINISH_NOW')});
        this.logoutModal.show();
        this.showSubmitted(this.checkSubmitted(), translate('FINISH_DESCRIPTION'));
    }

    private showReview(show?: boolean) {
        const visible = window.getComputedStyle(this.reviewPanel).getPropertyValue('display') !== 'none';
        if (show !== false && !visible && this.reviewButtonVisible) {
            this.reviewPanel.style.display = 'block';
            if (this.qid != undefined) {
                const s = this.overview[this.qid];
                scrollRangeIntoView(s[0].questionReview, s[s.length - 1].questionReview);
            }
            window.dispatchEvent(new Event('resize'));
        } else if (show !== true && visible) {
            this.reviewPanel.style.display = 'none';
            window.dispatchEvent(new Event('resize'));
        }
    }

    private readonly handleToolbar = async (event: Event): Promise<void> => {
        if (event.target instanceof Node) {
            if (this.statusBar.questionReview.contains(event.target)) {
                this.showReview();
            } else if (this.statusBar.langControl.contains(event.target)) {
                if (this.structure[this.qid].backendQid.length > 1) {
                    this.isNavigating = true;
                    this.updateNavigation();
                    try {
                        ++this.currentLanguage;
                        if (this.currentLanguage >= this.structure[this.qid].backendQid.length) {
                            this.currentLanguage = 0;
                        }
                        await dbPut('users', 'language', this.currentLanguage);
                        this.examTimer.setLanguage(this.currentLanguage);
                        await this.questionUi.setQuestion({
                            structure: this.structure[this.qid],
                            language: this.currentLanguage,
                            time: this.examTimer.getElapsed(),
                            forced: this.isForced,
                            validate: false,
                        });
                    } finally {
                        this.isNavigating = false;
                        this.updateNavigation();
                    }
                }
            } else if (this.statusBar.logoutControl.contains(event.target)) {
                this.showFinish();
            } else if (this.statusBar.prevControl.contains(event.target)) {
                this.change(this.prevCase);
                this.statusBar.prevControl.focus();
            } else if (this.statusBar.nextControl.contains(event.target)) {
                this.change(this.nextCase);
                this.statusBar.nextControl.focus();
            } else if (this.statusBar.prevCandidate.contains(event.target)) {
                this.change(this.prevCand);
                this.statusBar.prevCandidate.focus();
            } else if (this.statusBar.nextCandidate.contains(event.target)) {
                this.change(this.nextCand);
                this.statusBar.nextCandidate.focus();
            }
        }
    }

    private readonly prevCase = () => {
        if (this.qid <= 0) {
            return this.qid;
        }
        const q = this.structure[this.qid];
        const r = this.structure[this.qid - 1];
        return (q.round === r.round && q.room === r.room && q.circuit === r.circuit)
            ? this.qid - 1
            : this.qid;
    }

    private readonly nextCase = () => {
        if (this.qid >= this.structure.length - 1) {
            return this.qid;
        }
        const q = this.structure[this.qid];
        const r = this.structure[this.qid + 1];
        return (q.round === r.round && q.room === r.room && q.circuit === r.circuit)
            ? this.qid + 1
            : this.qid;
    }

    private readonly prevCand = () => {
        let prev = this.qid;
        let pass = 0;
        while (prev > 0) {
            const q = this.structure[prev];
            const r = this.structure[prev - 1];
            if (q.round !== r.round || q.room !== r.room || q.circuit !== r.circuit) {
                if (pass > 0) {
                    return prev;
                }
                ++pass;
            }
            --prev;
        }
        return (pass > 0) ? prev : this.qid;
    }

    private readonly nextCand = () => {
        let next = this.qid;
        while (next < this.structure.length - 1) {
            const q = this.structure[next];
            const r = this.structure[next + 1];
            if (q.round !== r.round || q.room !== r.room || q.circuit !== r.circuit) {
                return next + 1;
            }
            ++next;
        }
        return this.qid;
    }

    private async change(f: () => number) {
        if (this.isNavigating || this.isInvalid || this.questionUi.getNavigationLocked()) {
            return;
        }
        const x = f();
        if (this.qid != x) {
            if (this.isForced && (this.structure[x].round == null || this.structure[x].round != this.structure[this.qid].round)) {
                return;
            }
            try {
                this.isNavigating = true;
                this.updateNavigation();
                if(await this.questionUi.setQuestion({
                    structure: this.structure[x],
                    language: this.currentLanguage,
                    time: this.examTimer.getElapsed(),
                    forced: this.isForced,
                })) {
                    this.qid = x;
                }
                this.examTimer.update();
                this.noteViewer?.load(await dbGet('notes', this.qid));
                this.highlight?.load(await dbGet('notes', this.qid + '#'));
            } catch (err) {
                console.error('handleNext', err);
            } finally {
                this.noPrev = this.qid === this.prevCase();
                this.noNext = this.qid === this.nextCase();
                this.noPrevCand = this.qid === this.prevCand();
                this.noNextCand = this.qid === this.nextCand();
                this.isNavigating = false;
                this.updateNavigation();
            }
        }
    }

    private async finish(notify: boolean): Promise<void> {
        console.log('FINISH');
        if (notify) {
            const {remoteStatus} = await this.responses.fetchStatus({state: this.state, elapsed: this.examTimer.getElapsed(), scheduleVersion: Number.MAX_SAFE_INTEGER});
            if (!(remoteStatus || this.demo)) {
                return;
            }
        }
        this.logoutModal.reset();
        this.logoutModal.titleHtml(translate('STOPPED_TITLE'));
        this.logoutModal.bodyHtml(translate('STOPPED_CLEANUP'));
        this.logoutModal.show();
        this.examTimer.stop();
        //for (const question of this.structure) {
        //    for (const answer of question) {
        //        answer.destroy();
        //    }
        //}
        this.examTimer.destroy();
        this.robotCat?.destroy();
        this.accessibility?.destroy();
        this.noteViewer?.destroy();
        this.highlight?.destroy();
        this.calculator?.destroy();
        //window.removeEventListener('beforeunload', this.handleBeforeUnload);
        window.removeEventListener('offline', this.handleOffline);
        window.removeEventListener('online', this.handleOnline);
        if (this.eventSource) {
            this.eventSource.removeEventListener('exam-time', this.handleTimeMessage);
            this.eventSource.removeEventListener('exam-pending', this.handleWaitMessage);
            this.eventSource.removeEventListener('exam-started', this.handleStartMessage);
            this.eventSource.removeEventListener('exam-paused', this.handlePauseMessage);
            this.eventSource.removeEventListener('exam-resumed', this.handleResumeMessage);
            this.eventSource.removeEventListener('exam-stopped', this.handleStopMessage);
            this.eventSource.removeEventListener('exam-deleted', this.handleDeleteMessage);
            this.eventSource.destroy();
            this.eventSource = undefined;
        }
        const passive: AddEventListenerOptions & EventListenerOptions = {passive: true};
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
        this.contentPanel.removeEventListener('cut', this.blackhole_handler);
        this.contentPanel.removeEventListener('copy', this.blackhole_handler);
        this.contentPanel.removeEventListener('paste', this.blackhole_handler);
        this.examPanel.removeEventListener('scroll', this.handleScroll);
        this.reviewPanel.removeEventListener('scroll', this.handleScroll);
        this.reviewInner.removeEventListener('click', this.handleJump, passive);
        this.statusBar.statusBar.removeEventListener('click', this.handleToolbar, passive);
        //clearInterval(this.clockInterval);
        await this.questionUi.setQuestion({
            language: this.currentLanguage,
            time: this.examTimer.getElapsed(),
            notify: false,
            forced: this.isForced,
        });
        await this.questionUi.destroy();
        examCleanup();
        console.warn('PROMISE', typeof this.onDestroy);
        if (this.onDestroy) {
            await this.onDestroy();
        }
        this.logoutModal.hide();
        this.logoutModal.destroy();
        if (this.resolve) {
            const resolve = this.resolve;
            this.resolve = undefined;
            resolve();
        }
    }

    private handleModal = async (id: string): Promise<void> => {
        switch (id) {
            case 'continue':
                this.questionUi.setPaused(false);
                this.logoutModal.hide();
                break;
            case 'logout':
                if (await this.updateStatus({silent: false, scheduleVersion: Number.MAX_SAFE_INTEGER})) {
                    this.state = ExamState.Stopped;
                    await this.finish(true);
                } else {
                    this.showSubmitted(this.checkSubmitted(), translate('FINISH_DESCRIPTION'));
                }
                break;
            case 'pin':
                const buffer = await pinToKey(this.logoutModal.getValue('pin'));
                const key = btoa(new Uint8Array(buffer).reduce((acc, x) => acc + String.fromCharCode(x), ''));
                console.log('KEY', key);
                break;
            default:
                break;
        }
    }

    private readonly handleJump = async (event: Event): Promise<void> => {
        for (const q of this.overview) {
            for (const a of q) {
                if (event.target instanceof Node && a.questionReview.contains(event.target)) {
                    await a.select();
                    return;
                }
            }
        }
    }
}

/*
function holdingPage(parent: HTMLElement): Promise<void> {
    return new Promise(succ => {
        const holdingPanel = mkNode('div', {
            className: 'login-panel config-background', children: [
                mkNode('div', {
                    className: 'logo-panel', children: [
                        mkNode('img', { className: 'client-logo', attrib: { draggable: 'false', src: '/static/images/client-logo.png' } }),
                    ]
                }),
                mkNode('div', {
                    className: 'login-heading', children: [
                        mkNode('text', { text: 'Do not begin the exam until you are instructed to do so.' }),
                    ]
                }),
            ]
        });
        const holdingButton = mkNode('input', {
            className: 'navigation-primary exam-go', parent: holdingPanel, attrib: {
                type: 'button', value: 'begin'
            }
        });

        console.log('HOLDING CONSTRUCTED');

        holdingButton.onclick = (): void => {
            parent.removeChild(holdingPanel);
            succ();
        };
        parent.appendChild(holdingPanel);

        console.log('HOLDING ADDED');
    });
}
*/

interface StartQuestion {
    question: number;
    language: number;
    time: number;
}

export enum ExamState {
    Waiting = 'WAITING',
    Holding = 'HOLDING',
    Started = 'STARTED',
    Paused = 'PAUSED',
    Stopped = 'STOPPED',
    Deleted = 'DELETED',
}

interface SavedState {
    state: ExamState;
    elapsed: number;
    //schedule?: PractiqueNet.ExamJson.Definitions.Schedule;
    timestamp: number;
}

export function isState(x: unknown): x is ExamState {
    return typeof x === 'string' &&
    Object.keys(ExamState).reduce((acc: boolean, k: string): boolean => acc || x === (ExamState as {[x:string]:string})[k], false);
}

function isSavedState(x: unknown): x is SavedState {
    if (!isIndexed(x)) {
        console.warn('SavedState is not an object');
        return false;
    }
    if (!isState(x.state)) {
        console.warn('SavedState has invalid State');
        return false;
    }
    if (typeof x.elapsed !== 'number') {
        console.warn('SavedState has invalid elapsed time');
        return false;
    }
    return true;
}

interface InitState {
    state: ExamState;
    elapsed: number;
    schedule: VersionedSchedule;
    timestamp?: number;
    unsubmitted: {
        itemIndex: number;
        fieldIndex: number;
        remote: RemoteData;
    }[];
}

export async function deleteCurrentExam(): Promise<void> {
    let examCount = 0;
    const savedExamCount = await dbGet('users', 'examCount');
    if (typeof savedExamCount === 'number') {
        examCount = savedExamCount;
    }
    await dbPut('users', 'examCount', examCount + 1);
    await dbClearSelect();
}

export async function cleanUpOldExam(context: ExamCxt): Promise<InitState> {
    const responses = new ResponseModel(context.meta.answer_aes_key, context.candidateId, context.meta.demo ?? false, context.structure);

    const savedSchedule = await dbGet('users', 'schedule');
    let schedule: VersionedSchedule = {schedule: context.meta.timing?.schedule ?? [], version: 0};
    if (savedSchedule && isVersionedSchedule(savedSchedule)) {
        schedule = savedSchedule;
    }

    const savedState = await dbGet('users', 'state');
    let elapsed = 0;
    let timestamp = Date.now();
    let state = ExamState.Started;
    if (savedState && isSavedState(savedState)) {
        console.debug('SAVED_STATUS', savedState);
        state = savedState.state ?? state;
        timestamp = savedState.timestamp;
        elapsed = savedState.elapsed;
    }

    const unsubmitted = await responses.getUnsubmitted();

    const {remoteStatus, timestamp: fetchTimestamp} = await responses.fetchStatus({
        state,
        elapsed,
        responses: unsubmitted.map(({remote}) => remote),
        returnResponses: true,
        scheduleVersion: schedule.version,
    }, true);

    if (remoteStatus) {
        console.debug('REMOTE_STATUS', remoteStatus)

        // eslint-disable-next-line @typescript-eslint/no-empty-function
        await responses.setSubmitted(unsubmitted, () => {});

        state = remoteStatus.state ?? state;
        if (remoteStatus.elapsed !== undefined) {
            elapsed = remoteStatus.elapsed;
        }

        if (fetchTimestamp !== undefined) {
            timestamp = fetchTimestamp;
        }

        if (remoteStatus.schedule) {
            schedule = {schedule: remoteStatus.schedule, version: remoteStatus.scheduleVersion || 0};
        }

        await responses.updateResponses(remoteStatus);
    }

    return {state, elapsed, schedule, timestamp, unsubmitted};
}

export async function runExam(state: InitState, context: ExamCxt): Promise<void> {
    console.debug('RUN EXAM');
    const responses = new ResponseModel(context.meta.answer_aes_key, context.candidateId, context.meta.demo ?? false, context.structure);
    const startQuestion: StartQuestion = {
        question: 0,
        language: 0,
        time: state.elapsed,
    }
    const savedLanguage = await dbGet('users', 'language');
    if (typeof savedLanguage === 'number') {
        startQuestion.language = savedLanguage;
    }
    const lastQuestion = await responses.getLastQuestion();
    if (lastQuestion) {
        console.log('LAST_QUESTION', lastQuestion);
        startQuestion.time = lastQuestion.elapsed;
        if (lastQuestion.nextQuestion !== undefined) {
            startQuestion.question = lastQuestion.nextQuestion;
        }
    }
    const cxt: ExamContext = {
        ...context,
        responses: responses,
        onDestroy: async () => {
            console.warn('*** onDestroy CALLED ***');
            await deleteCurrentExam();
            await context.onDestroy();
        },
    }
    const xv = new ExamViewer({context: cxt, startQuestion, init: state});
    await xv.init({context: cxt, state: state.state, startTime: startQuestion.time});
}
